.services-container{
    display: flex;
    align-items: center;
    justify-content: center;
    h3{
        color: #3498db;
    }
    @media (max-width: 1440px) {
        flex-direction: column;
        overflow: hidden;
        h1{
            text-align: center;

        }
        p{
            text-align: center;
        }
    }
    .services-image{
        width: 50%;
        
        padding: 7%;
        img{
            width: 600px;
            overflow: hidden!important ;
        height: 400px;
        border-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -50px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        @media (max-width: 768px) {
            overflow: hidden!important;
            width: 350px;
            height: 250px;
    }
        }
        @media (max-width: 768px) {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 1%;
            justify-content: center;
        }
    }

    .services-text{
        width: 50%;
        padding: 7%;
        @media (max-width: 768px) {
            width: 80%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            overflow: hidden;
            p{
                color: grey;
                font-size: 1rem;
                margin: 10%;
            }
    
    }
    }
    
}