.faq-container {
    max-width: 900px;
    margin: 3% auto;
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 50px;
    @media (max-width: 768px) {
     max-width: 350px;       
        
    }
  }
  
  .faq-item {
    border-radius: 5px;
    margin-bottom: 10px;
    overflow: hidden;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -50px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    padding: 2% 5%;
    @media (max-width: 768px) {
            
        
}
  }
  
  .faq-question {
    padding: 2cqmin;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .faq-toggle {
    font-size: 25px;
    color: #555;
  }
  
  .faq-answer {
    padding: 15px;
    background-color: #fff;
    color: #333;
    border-top: 1px solid #ddd;
  }
  
  