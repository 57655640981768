.Footer-Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-top: 2px solid #ccc;
    font-family: Arial, sans-serif;
    overflow-x: hidden;
  
    .Footer-Logo-Container {
      text-align: center;
      margin-bottom: 20px;
  
      img {
        width: 100px;
        margin-bottom: 10px;
      }
  
      h3 {
        font-size: 1.5rem;
        color: #333;
        margin: 0;
      }
    }
  
    .Footer-Contact-form {
      text-align: center;
      margin-bottom: 30px;
      overflow: hidden;
      p {
        font-size: 1.2rem;
        font-weight: bold;
        color: #444;
        margin-bottom: 10px;
      }
  
      a {
        display: block;
        margin: 5px 0;
        font-size: 1rem;
        color: #333;
        text-decoration: none;
        transition: color 0.3s ease;
  
        &:hover {
          color: #0073e6;
        }
  
        svg {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
    }
  
    .contact-form {
      width: 100%;
      max-width: 500px;
      background: #fff;
      padding: 50px;
      border: 1px solid #ddd;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      
      .form-group {
        margin-bottom: 15px;
  
        label {
          display: block;
          font-size: 1rem;
          margin-bottom: 5px;
          color: #555;
        }
  
        input,
        textarea {
          width: 95%;
          padding: 10px;
          font-size: 1rem;
          border: 1px solid #ddd;
          border-radius: 5px;
          outline: none;
          transition: border-color 0.3s ease;
  
          &:focus {
            border-color: #0073e6;
          }
        }
  
        textarea {
          resize: none;
          height: 100px;
        }
      }
  
      .cta-btn {
        display: inline-block;
        width: 100%;
        padding: 10px;
        font-size: 1rem;
        color: #fff;
        background-color: #0073e6;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        text-align: center;
        transition: background-color 0.3s ease;
  
        &:hover {
          background-color: #005bb5;
        }
      }
    }
  
    @media (max-width: 600px) {
      padding: 15px;
  
      .Footer-Logo-Container {
        img {
          width: 80px;
        }
  
        h3 {
          font-size: 1.2rem;
        }
      }
  
      .Footer-Contact-form {
        p {
          font-size: 1rem;
        }
  
        a {
          font-size: 0.9rem;
        }
      }
  
      .contact-form {
        overflow: hidden !important;
        .form-group {
          label {
            font-size: 0.9rem;
          }
  
          input,
          textarea {
            font-size: 0.9rem;
          }
        }
  
        .cta-btn {
          font-size: 0.9rem;
          padding: 8px;
        }
      }
    }
  }
  