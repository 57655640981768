
.Aboutus-Container{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 1440px) {
        flex-direction: column;
        h1{
            text-align: center;

        }
        p{
            text-align: center;
        }
    }
}
.Left-Side{
    width: 50%;
    height: 400px;
    padding: 7%;
    border-radius: 50px;
    @media (max-width: 768px) {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1%;
        justify-content: center;
    }
    img{
        width: 600px;
        height: 400px;
        border-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -50px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
        @media (max-width: 768px) {
            
                width: 350px;
                height: 250px;
        }
    }
}
.Right-Side{
    width: 50%;
    height: 450px;
    overflow: hidden;
    padding: 7%;
    h1{
        
        @media (max-width: 768px) {
            margin-top: 15%;
        }
    }
    p{
        color: grey;
        font-size: clamp(1rem, 2.2vw, 1.2rem);
    }
    @media (max-width: 768px) {
        width: 90%;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        p{
            color: grey;
            font-size: 1rem;
            margin: 10%;
        }

}
}