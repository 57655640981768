.Banner{
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center ;
    justify-content: center;
    z-index: -1;
}
.Banner::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('./img/banner.jpg') no-repeat center center;
    background-size: cover;
    filter: blur(5px);
    z-index: -1;
}
.Banner h1 {
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
}