body {
    font-family: 'Ubuntu', sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #F8F8F8;
    border-radius: 20px;
    margin: 20px;
    padding: 10px 20px;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .logo-container {
    display: flex;
    
    align-items: center;
  }
  
  .logo {
    width: 150px;
    height: 70px;
    margin-right: 10px;
  }
  
  .site-name {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
  }
  .navbar-links {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  .navbar-item {
    margin-left: 20px;
  }
  
  .navbar-item a {
    text-decoration: none;
    color: #333333;
    font-weight: 500;
    font-size: 18px;
    transition: color 0.3s ease;
    @media (max-width: 768px) {
        .navbar-item a{
            display: none;
        }
      }
  }
  
  .navbar-item a:hover {
    color: #3498db;
  }

  @media (max-width: 768px) {
    .navbar-item a{
        display: none;
    }
  }
  .Menu-Button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #333333;
  }
  @media (min-width: 768px) {
    .Menu-Button {
        display: none;
    }
  }
  @media (max-width: 768px) {
    .logo{
        width: 120px;
        height: 60px;
    }
  }