.how-Container{
    display: flex;
    width: 100%;
    p{
        font-size: clamp(1rem, 2.2vw, 1.2rem);
        color: grey;
    }
    @media (max-width: 1440px) {
        flex-direction: column;
        overflow: hidden;
        h1{
            text-align: center;

        }
        p{
            text-align: center;
        }
    }
}
.Left-Side1{
    width: 50%;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5% ;
    
    @media (max-width: 1440px) {
        width: 100%;
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 0;
        justify-content: center;
        p{
            width: 60%;
        }
    }
}
.Right-Side1{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 500px;
    img{
        width: 600px;
        height: 400px;
        border-radius: 50px;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;    }
        @media (max-width: 1440px) {
            width: 100%;
            padding: 0;
            display: flex;
            margin-top: 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            p{
                color: grey;
                font-size: 1rem;
                margin: 10%;
            }
            img{
                width: 350px;
                height: 250px;
            }
}
}